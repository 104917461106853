* {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
  background: transparent;
  font-weight: normal;
  text-decoration: none;
  outline: none;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
:root {
  touch-action: pan-x pan-y;
  height: 100%;
}
input[type="date"]::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}
input[type="date"]::-webkit-clear-button {
  display: none;
  opacity: 0;
}
ol,
ul,
li {
  list-style: none;
}
del {
  text-decoration: line-through;
}
body {
  font-family: "GT-Eesti", Arial, Helvetica, sans-serif;
  font-size: 17px;
  color: #000;
  overflow-x: hidden;
  min-width: 320px;
  font-weight: 500;
}
input,
textarea,
select {
  font-family: "GT-Eesti", Arial, Helvetica, sans-serif;
  font-weight: 500;
}

a {
  color: #000;
}
.thumb {
  display: block;
}
.thumb img {
  display: block;
  width: 100%;
}
input[type="number"] {
  -moz-appearance: textfield;
}
input[type="number"]:hover,
input[type="number"]:focus {
  -moz-appearance: number-input;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="date"]::-moz-clear {
  display: none;
}

input[type="number"] {
  -moz-appearance: textfield;
}
p {
  line-height: 1.6em;
  font-size: 16px;
  font-family: "GT-Eesti", Arial;
  font-weight: 500;
  color: #000;
}
.hidden {
  display: none;
}

@font-face {
  font-family: "Gordita-Medium";
  src: url("../fonts/Gordita-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gordita-Light";
  src: url("../fonts/Gordita-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gordita-Regular";
  src: url("../fonts/Gordita-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
br.clear {
  clear: both;
  line-height: 0;
}
.wrapper {
  width: 90%;
  margin: 0 auto;
  max-width: 1200px;
}
.flex-wrapper {
  display: flex;
  justify-content: space-between;
}
@media all and (max-width: 280px) {
  .mobile {
    margin-right: 40px;
  }
}

::placeholder {
  color: #9b9b9b;
  opacity: 1;
}

:-ms-input-placeholder {
  color: #9b9b9b;
}

::-ms-input-placeholder {
  color: #9b9b9b;
}
h6 {
  margin: 0;
}
img {
  width: 100%;
  display: block;
}
.slick-next {
  filter: drop-shadow(green);
}
::-webkit-calendar-picker-indicator {
  display: none !important;
}
/* reactcalendar */

.react-calendar {
  width: 75%;
  border: none !important;
  background: #fff !important;
  /* box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px; */
  margin: 10px auto;
  /* border-radius: 10px; */
  /* background: #fcfcfc !important;
	padding: 20px; */
}

.react-calendar__navigation__prev2-button,
.react-calendar__navigation__next2-button {
  display: none;
}

.react-calendar__navigation__label {
  flex-grow: unset;
}

.react-calendar__tile--active {
  background: #fc8b62 !important;
  color: unset !important;
  border-radius: 50%;
  color: #fff !important;
}

.react-calendar__tile:disabled {
  background-color: #d9dad7;
  opacity: 0.3;
  cursor: not-allowed;
}
button.react-calendar__month-view__days__day--weekend {
  color: #fff !important;
}
.react-calendar__navigation button:disabled {
  background-color: #d9dad7;
}

button.react-calendar__tile {
  color: #000;
}
.react-calendar__tile {
  padding: 16px 10.6667px !important;
}
button.react-calendar__month-view__days__day--weekend {
  color: #d10000 !important;
}
.react-calendar__tile--now {
  background: #41559c;
  color: rgb(0, 0, 0) !important;
}
button.react-calendar__month-view__days__day--neighboringMonth {
  color: #757575 !important;
}

button.react-calendar__navigation__next-button,
button.react-calendar__navigation__prev-button,
.react-calendar__navigation__label__labelText--from {
  color: rgb(0, 0, 0) !important;
}

.dett-container {
  /* background-size: contain;
	display: flex;
	align-items: center; */
  background: url("https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/dett/10-02-2022/floating-background.svg");
}

@media all and (max-width: 480px) {
  .dett-container {
    background: none;
  }
}
input[type="checkbox"] {
  /* -webkit-appearance: none;
	appearance: none; */
  /* For iOS < 15 to remove gradient background */
  background: rgb(213, 16, 16) !important;
  /* Not removed via appearance */
  margin: 0;
  border: 1px solid red !important;
  font: inherit;
  color: red;
  width: 1.15em;
  height: 1.15em;
  border: 0.15em solid red;
  border-radius: 0.15em;
  transform: translateY(-0.075em);
}
.OtpInput {
  margin-right: 22px;
  width: 50px !important;
  height: 62px;
  font-size: 24px;
  font-family: "Gordita-Regular";
  color: #191919;
  border-bottom: 2px solid #cfcfcf;
  /* outline-color: #fc8b62; */
  /* border-radius: 8px; */
  margin-bottom: 10px;
}

@media all and (max-width: 1280px) {
  .OtpInput {
    width: 45px !important;
  }
}

@media all and (max-width: 1080px) {
  .OtpInput {
    width: 30px !important;
  }
}

@media all and (max-width: 980px) {
  .OtpInput {
    width: 26px !important;
    height: 40px;
    font-size: 20px;
  }
}

@media all and (max-width: 768px) {
  .OtpInput {
    width: 20px !important;
    height: 40px;
    font-size: 18px;
  }
}

@media all and (max-width: 640px) {
  .OtpInput {
    width: 48px !important;
    margin-bottom: 5px;
  }
}

@media all and (max-width: 480px) {
  .OtpInput {
    width: 48px !important;
    font-size: 16px;
  }
  .react-calendar__tile--active {
    border-radius: 95%;
  }
}

@media all and (max-width: 360px) {
  .OtpInput {
    width: 40px !important;
  }
}

.OtpInput:focus {
  border-bottom: 2px solid #fc8b62;
  /* color: #fc8b62 !important; */
  /* font-c */
}

.error {
  margin-right: 22px;
  width: 50px !important;
  height: 62px;
  font-size: 24px;
  font-family: "Gordita-Regular";
  color: #191919;
  border-bottom: 2px solid #dd2424;
  outline-color: #fc8b62;
  margin-bottom: 10px;
  /* border-radius: 8px; */
}

@media all and (max-width: 1280px) {
  .error {
    width: 45px !important;
    margin-bottom: 10px;
  }
}

@media all and (max-width: 1080px) {
  .error {
    width: 30px !important;
    height: 56px;
    font-size: 22px;
  }
}

@media all and (max-width: 980px) {
  .error {
    width: 26px !important;
    height: 40px;
    font-size: 20px;
  }
}

@media all and (max-width: 768px) {
  .error {
    width: 20px !important;
    height: 40px;
    font-size: 18px;
  }
}

@media all and (max-width: 640px) {
  .error {
    width: 48px !important;
    margin-bottom: 5px;
  }
}

@media all and (max-width: 480px) {
  .error {
    width: 48px !important;
    font-size: 16px;
  }
}

@media all and (max-width: 360px) {
  .error {
    width: 40px !important;
  }
}

.OtpInput:last-child() {
  margin-right: 0;
}
.OtpStyle {
  width: 80%;
}

.errorstyle {
  width: 80%;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  appearance: none;
}

input[type="checkbox"] {
  width: 20px;
  height: 20px;
}

input[type="checkbox"]:checked {
  accent-color: #d57c5d;
  color: #fff !important;
}
input[type="date"]::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
  width: 15px;
  padding: 0px;
  margin: 0px;
  margin-top: 10px;
}
/* input[type="date"]::-webkit-datetime-edit-text {
  -webkit-appearance: none;
  display: none;
}
input[type="date"]::-webkit-datetime-edit-month-field {
  -webkit-appearance: none;

  display: none;
}
input[type="date"]::-webkit-datetime-edit-day-field {
  -webkit-appearance: none;
  display: none;
}
input[type="date"]::-webkit-datetime-edit-year-field {
  -webkit-appearance: none;
  display: none;
} */
